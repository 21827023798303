
import { Vue, Component, Prop } from "vue-property-decorator";
import * as ApiServices from "@/api/helpers/Api";
import { ElForm } from "element-ui/types/form";
import DeletePopover from "./DeletePopover.vue";
import Notify from "@/utils/notifications";
import Config from "@/models/Config";
import * as SystemServices from "@/api/helpers/System";
import * as CardSettingServices from "@/api/helpers/CardSetting";
import _ from "underscore";
import { Count } from "@/api/Gondor";
import { KeySystem } from "@/api/helpers/KeySystem";
import moment from "moment-timezone";

interface FormRule {
  required?: boolean;
  type?: string;
  min?: number;
  max?: number;
  validator?: (
    rule: any,
    value: any,
    callback: (error?: string) => void
  ) => void;
  trigger?: string;
  pattern?: RegExp;
  message: string;
}

interface Options {
  value: string;
  label: string;
  type: ApiServices.Type;
}

@Component({
  components: {
    DeletePopover,
  },
})
export default class ConfigForm extends Vue {
  @Prop({ required: true }) readonly model!: ApiServices.Setting[];
  @Prop({ required: true }) readonly cardId!: number;
  @Prop({ required: true }) readonly loading!: boolean;
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly systemId!: number;
  @Prop({ required: true }) readonly keys!: Count<KeySystem>;

  showDialog = false;
  newSetting = new Config();
  isSubmitting = false;
  creation = false;
  updateConfig = false;
  keyId = null;
  editId = 0;

  rules: { [key: string]: FormRule[] } = {
    keySystemId: [
      {
        required: true,
        message: "La llave es requerida",
        trigger: "blur",
      },
    ],
    value: [
      {
        required: true,
        message: "Es valor es requerido",
        trigger: "blur",
      },
    ],
  };

  selectOptions: Options[] | [] = [];

  changeKey(id: any) {
    let isboolean = false;
    const keys = _.filter(this.keys.rows, (key: any) => {
      if (key.id == id && key.dataType == "boolean") {
        isboolean = true;
      }
      return key.id == id;
    });
    this.newSetting = {
      ...this.newSetting,
      value: isboolean ? false : "",
      dataType: keys[0].dataType,
    };
  }

  getDatatType(key: any) {
    const keys = _.filter(this.keys.rows, (item: any) => {
      return item.key == key;
    });
    if (keys.length) {
      return keys[0].dataType;
    }
  }

  valueFormat(value: any, row: any) {
    if (
      (row.dataType && row.dataType == "date") ||
      this.getDatatType(row.key) == "date"
    ) {
      const date = moment(value).format("DD/MM/YYYY");
      return date.toString();
    } else {
      return value;
    }
  }

  createConfig() {
    this.openDialog(true);
    this.creation = true;
    this.newSetting = new Config();
  }

  editSetting(id: number) {
    this.updateConfig = true;
    this.editId = id;
  }

  openDialog(status: boolean) {
    this.showDialog = status;
  }

  closeDrawer() {
    this.$emit("closeDrawer", false);
  }

  findStartTimeActivity() {
    return this.model.find(setting => setting.key === "startTimeActivity");
  }

  isInitDateSet(type: "date" | "time"): boolean {
    if (type === "date") {
      return this.model.some(setting => setting.key === "startDateConvertion");
    }

    return this.model.some(setting => setting.key === "startTimeActivity");
  }

  isAlreadyCreated(key: string) {
    return this.model.some(setting => setting.key === key);
  }

  transformValue(value: string, key: string): string {
    try {
      if (key === "activeDays") {
        const parsedArray = JSON.parse(value);
        if (Array.isArray(parsedArray)) {
          return (parsedArray as unknown) as string;
        }

        return value;
      }

      return value;
    } catch (e) {
      return value;
    }
  }

  createSetting() {
    (this.$refs["newSetting"] as ElForm).validate(valid => {
      if (valid) {
        if (this.cardId === undefined) {
          const keySystemName = this.keys.rows.find(
            key => key.id === this.newSetting.keySystemId
          )?.name;
          this.$emit("newSetting", { ...this.newSetting, name: keySystemName });
          this.openDialog(false);
        } else {
          this.isSubmitting = true;
          if (Array.isArray(this.newSetting.value)) {
            this.newSetting.value = JSON.stringify(this.newSetting.value);
          }
          CardSettingServices.create(
            _.extend(this.newSetting, { cardId: this.cardId })
          )
            .then(() => {
              Notify.successful("Configuración creada exitosamente.");
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.isSubmitting = false;
              this.openDialog(false);
              this.$emit("updateModel", this.cardId);
            });
        }
      }
    });
  }

  updateSetting(id: number, value: string) {
    if (this.cardId === undefined) {
      this.updateConfig = false;
    } else {
      this.isSubmitting = true;
      if (Array.isArray(value)) {
        this.newSetting.value = JSON.stringify(value);
      } else {
        this.newSetting.value = value;
      }
      const { value: cardValue } = this.newSetting;
      CardSettingServices.update(id, { value: cardValue })
        .then(() => {
          Notify.successful("Configuración editada exitosamente.");
        })
        .catch(error => {
          Notify.gebServerError(error);
        })
        .finally(() => {
          this.$emit("updateModel", this.cardId);
          this.isSubmitting = false;
          this.updateConfig = false;
        });
    }
  }

  destroyCardSetting(id: number) {
    if (this.cardId === undefined) {
      this.$emit("deleteSetting", id);
    } else {
      CardSettingServices.destroy(id)
        .then(() => {
          Notify.successful("Configuración borrada exitosamente.");
        })
        .catch(error => {
          Notify.gebServerError(error);
        })
        .finally(() => {
          this.$emit("updateModel", this.cardId);
        });
    }
  }

  get action() {
    const create = "Crear";
    const update = "Actualizar";
    return this.creation ? create : update;
  }

  cancel() {
    this.updateConfig = false;
  }
}
