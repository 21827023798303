import { Count, Gondor, Response } from "../Gondor";

export interface CardSetting {
  id: number;
  key: string;
  value: string;
  name: string;
  description: null | string;
  cardId: number;
  createdAt: Date;
  updatedAt: Date;
}

const ACTIONS = {
  CARD_ITEM_SETTING: "/card/{id}/setting/",
  CARD_SETTING: "/cardsetting/",
  CARD_SETTING_ITEM: "/cardsetting/{id}/",
};

export const find = (apiSystemId: number) => {
  return Gondor.API.get<Response<Count<CardSetting>>>(
    ACTIONS.CARD_ITEM_SETTING.replace("{id}", String(apiSystemId))
  );
};

export function create(body: object) {
  return Gondor.API.post(ACTIONS.CARD_SETTING, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.CARD_SETTING_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.CARD_SETTING_ITEM.replace("{id}", id));
}
