import { Gondor } from "../Gondor";

const ACTIONS = {
  INCOME: "/income/",
  INCOME_ITEM: "/income/{id}/",
  ADD_SYSTEMS: "/income/add/system",
  REMOVE_SYSTEMS: "/income/remove/system",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.INCOME, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.INCOME_ITEM.replace("{id}", id));
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.INCOME, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(ACTIONS.INCOME_ITEM.replace("{id}", id), body);
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.INCOME_ITEM.replace("{id}", id));
}

export function addSystems(body: object) {
  return Gondor.API.post(ACTIONS.ADD_SYSTEMS, body);
}

export function removeSystems(body: object) {
  return Gondor.API.post(ACTIONS.REMOVE_SYSTEMS, body);
}
