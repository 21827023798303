
import { Component, Prop, Vue } from "vue-property-decorator";
import Notify from "@/utils/notifications";
import * as SatServices from "@/api/helpers/Sat";
import * as DebtServices from "@/api/helpers/Debt";
import * as StateServices from "@/api/helpers/State";
import * as LenderServices from "@/api/helpers/Lender";
import * as IncomeServices from "@/api/helpers/Income";
import * as FeatureServices from "@/api/helpers/Feature";
import * as DepositServices from "@/api/helpers/Deposit";
import * as MaxPriceServices from "@/api/helpers/MaxPrice";
import * as ProfessionServices from "@/api/helpers/Profession";
import * as InvestmentServices from "@/api/helpers/Investment";
import * as MotivationServices from "@/api/helpers/Motivation";
import * as VehicleSizeServices from "@/api/helpers/VehicleSize";
import * as VehicleTypeServices from "@/api/helpers/VehicleType";
import * as SchoolGradeServices from "@/api/helpers/SchoolGrade";
import * as CompanyYearsServices from "@/api/helpers/CompanyYears";
import * as StudiesFieldServices from "@/api/helpers/StudiesField";
import * as DedicatedTimeServices from "@/api/helpers/DedicatedTime";
import * as MonthlyPaymentServices from "@/api/helpers/MonthlyPayment";
import * as VehiclePurposeServices from "@/api/helpers/VehiclePurpose";
import * as CompanyActivityServices from "@/api/helpers/CompanyActivity";
import * as CompanyEmployeesServices from "@/api/helpers/CompanyEmployees";
import * as CardBenefitServices from "@/api/helpers/CardBenefit";
import Column from "@/models/Column";
import Catalog from "@/models/Catalog";
import Card from "@/models/Card";
import Rule from "@/models/Rule";
import empty from "is-empty";
import _ from "underscore";

@Component
export default class RuleForm extends Vue {
  @Prop() card!: Card;
  @Prop() columns!: Array<Column>;

  catalogQuery = {
    order: "name:asc",
    system: 0,
  };
  originalComparators = [
    {
      label: "Igual a",
      value: "=",
      types: ["id", "boolean", "date", "number", "string"],
    },
    {
      label: "Diferente de",
      value: "<>",
      types: ["id", "boolean", "date", "number", "string"],
    },
    {
      label: "Mayor que",
      value: ">",
      types: ["date", "number"],
    },
    {
      label: "Mayor o igual que",
      value: ">=",
      types: ["date", "number"],
    },
    {
      label: "Menor que",
      value: "<",
      types: ["date", "number"],
    },
    {
      label: "Menor o igual que",
      value: "<=",
      types: ["date", "number"],
    },
  ];
  comparators: Array<any> = this.originalComparators;
  dataTypes: Array<string> = [];
  sats: Array<Catalog> = [];
  debts: Array<Catalog> = [];
  states: Array<Catalog> = [];
  lenders: Array<Catalog> = [];
  incomes: Array<Catalog> = [];
  features: Array<Catalog> = [];
  deposits: Array<Catalog> = [];
  maxPrices: Array<Catalog> = [];
  investments: Array<Catalog> = [];
  motivations: Array<Catalog> = [];
  professions: Array<Catalog> = [];
  vehicleSizes: Array<Catalog> = [];
  vehicleTypes: Array<Catalog> = [];
  companyYears: Array<Catalog> = [];
  schoolGrades: Array<Catalog> = [];
  studiesFields: Array<Catalog> = [];
  dedicatedTimes: Array<Catalog> = [];
  vehiclePurposes: Array<Catalog> = [];
  monthlyPayments: Array<Catalog> = [];
  companyEmployees: Array<Catalog> = [];
  companyActivities: Array<Catalog> = [];
  cardBenefits: Array<Catalog> = [];

  addRule() {
    if (this.validateLastRule()) this.card.rules.push(new Rule());
    else Notify.warning("Todos los campos son requeridos.");
  }

  removeRule(index: number) {
    this.card.rules.splice(index, 1);
  }

  validateLastRule() {
    const length = this.card.rules.length;
    if (length == 0) return true;

    const lastRule = this.card.rules[length - 1];
    if (lastRule.value && lastRule.operator && lastRule.columnId) return true;
    return false;
  }

  clearRules() {
    this.card.rules = _.filter(this.card.rules, (r: Rule) => {
      return !empty(r.value) && !empty(r.operator) && !empty(r.columnId);
    });
  }

  changeDataType(id: number, index: number) {
    this.dataTypes[index] = this.getDataType(id);
    this.comparators = _.filter(this.originalComparators, c => {
      return _.contains(c.types, this.dataTypes[index]);
    });
  }

  getCatalog(columnId: number) {
    const column = _.find(this.columns, (c: Column) => {
      return c.id == columnId;
    });

    if (column) {
      const code = column.code;
      const codeSplit = code.split(".");
      let columnName = _.find(codeSplit, (c: string) => {
        return c.toLowerCase().includes("id") || c == "vehiclePaymentMethod";
      });
      columnName = !columnName ? codeSplit[codeSplit.length - 1] : columnName;

      if (columnName) {
        columnName = columnName.toLowerCase().replace("id", "");
        switch (columnName) {
          case "state":
            return this.states;
          case "income":
            return this.incomes;
          case "profession":
            return this.professions;
          case "debt":
            return this.debts;
          case "deposit":
            return this.deposits;
          case "maxprice":
            return this.maxPrices;
          case "monthlypayment":
            return this.monthlyPayments;
          case "vehiclesize":
            return this.vehicleSizes;
          case "vehicletype":
            return this.vehicleTypes;
          case "feature":
            return this.features;
          case "lender":
            return this.lenders;
          case "vehiclepurpose":
            return this.vehiclePurposes;
          case "sat":
            return this.sats;
          case "companyactivity":
            return this.companyActivities;
          case "companyemployees":
            return this.companyEmployees;
          case "companyyears":
            return this.companyYears;
          case "dedicatedtime":
            return this.dedicatedTimes;
          case "investment":
            return this.investments;
          case "motivation":
            return this.motivations;
          case "schoolgrade":
            return this.schoolGrades;
          case "studiesfield":
            return this.studiesFields;
          case "vehiclepaymentmethod":
            return [
              {
                id: "A crédito",
                name: "A crédito",
              },
              {
                id: "De contado",
                name: "De contado",
              },
            ];
          case "cardbenefit":
            return this.cardBenefits;
          default:
            return [];
        }
      }
    }
  }

  getDataType(id: number) {
    const column = _.find(this.columns, (c: Column) => {
      return c.id == id;
    });
    const dataType = column && column.dataType ? column.dataType : "";
    return dataType;
  }

  getStates() {
    StateServices.find(this.catalogQuery)
      .then(res => {
        this.states = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getIncomes() {
    IncomeServices.find(this.catalogQuery)
      .then(res => {
        this.incomes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getDebts() {
    DebtServices.find(this.catalogQuery)
      .then(res => {
        this.debts = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getLenders() {
    LenderServices.find(this.catalogQuery)
      .then(res => {
        this.lenders = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getProfessions() {
    ProfessionServices.find(this.catalogQuery)
      .then(res => {
        this.professions = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getDeposits() {
    DepositServices.find(this.catalogQuery)
      .then(res => {
        this.deposits = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getMaxPrices() {
    MaxPriceServices.find(this.catalogQuery)
      .then(res => {
        this.maxPrices = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getMonthlyPayments() {
    MonthlyPaymentServices.find(this.catalogQuery)
      .then(res => {
        this.monthlyPayments = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getVehicleSizes() {
    VehicleSizeServices.find(this.catalogQuery)
      .then(res => {
        this.vehicleSizes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getVehicleTypes() {
    VehicleTypeServices.find(this.catalogQuery)
      .then(res => {
        this.vehicleTypes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getFeatures() {
    FeatureServices.find(this.catalogQuery)
      .then(res => {
        this.features = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getVehiclePurposes() {
    VehiclePurposeServices.find(this.catalogQuery)
      .then(res => {
        this.vehiclePurposes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getSats() {
    SatServices.find(this.catalogQuery)
      .then(res => {
        this.sats = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getCompanyYears() {
    CompanyYearsServices.find(this.catalogQuery)
      .then(res => {
        this.companyYears = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getCompanyEmployees() {
    CompanyEmployeesServices.find(this.catalogQuery)
      .then(res => {
        this.companyEmployees = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getCompanyActivities() {
    CompanyActivityServices.find(this.catalogQuery)
      .then(res => {
        this.companyActivities = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getDedicatedTimes() {
    DedicatedTimeServices.find(this.catalogQuery)
      .then(res => {
        this.dedicatedTimes = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getInvestments() {
    InvestmentServices.find(this.catalogQuery)
      .then(res => {
        this.investments = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getMotivations() {
    MotivationServices.find(this.catalogQuery)
      .then(res => {
        this.motivations = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getSchoolGrades() {
    SchoolGradeServices.find(this.catalogQuery)
      .then(res => {
        this.schoolGrades = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getStudiesFields() {
    StudiesFieldServices.find(this.catalogQuery)
      .then(res => {
        this.studiesFields = res.data.data;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  getCardBenefits() {
    CardBenefitServices.find(this.catalogQuery)
      .then(res => {
        this.cardBenefits = res.data.data;
        console.log(this.cardBenefits);
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  mounted() {
    this.dataTypes = _.map(this.card.rules, (r: Rule) => {
      if (r.column.dataType == "id") {
        const value = parseInt(r.value.toString());
        r.value = isNaN(value) ? r.value.toString() : value;
      }
      return this.getDataType(r.columnId);
    });

    this.catalogQuery["system"] = this.card.systemId;
    this.getSats();
    this.getDebts();
    this.getStates();
    this.getLenders();
    this.getIncomes();
    this.getFeatures();
    this.getDeposits();
    this.getMaxPrices();
    this.getInvestments();
    this.getMotivations();
    this.getProfessions();
    this.getVehicleSizes();
    this.getVehicleTypes();
    this.getCompanyYears();
    this.getSchoolGrades();
    this.getStudiesFields();
    this.getDedicatedTimes();
    this.getVehiclePurposes();
    this.getMonthlyPayments();
    this.getCompanyEmployees();
    this.getCompanyActivities();
    this.getCardBenefits();
  }
}
