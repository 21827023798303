
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { OfferType, CardItem } from "@/models/Card";
import Regex from "@/utils/regex";
import Notify from "@/utils/notifications";
import * as SystemServices from "@/api/helpers/System";
import * as CardSettingServices from "@/api/helpers/CardSetting";
import { ElForm } from "element-ui/types/form";
import { Type } from "@/api/helpers/Api";

interface AssetUpload {
  name: string;
  percentage: number;
  raw: RawUpload;
  size: number;
  status: string;
  uid: number;
}

interface RawUpload extends File {
  uid: number;
  lastModifiedDate: any;
  size: number;
  type: string;
}

@Component({
  components: {},
})
export default class CardForm extends Vue {
  @Prop() card!: CardItem;
  @Prop() systemId!: number;

  readonly maxImageSize = 500000;
  readonly imgTypeRegex = /^image\/.+$/;
  loadingOfferTypes = false;
  cardSettings: CardSettingServices.CardSetting[] = [];
  configType: Type = "card";
  configLoading = false;
  showConfigDrawer = false;
  offerTypes: Array<OfferType> = [];
  activeUpload = true;
  formData = new FormData();
  disableTimeActivity =
    !Array.isArray(this.card.activeDays) || this.card.activeDays.length === 0;
  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    subtitle: [
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    content: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    url: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        pattern: Regex.url,
        message:
          "No es una url válida, debe respetar el formato http://www.url.com o similar.",
      },
    ],
    startTimeActivity: [
      {
        required: false,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    endTimeActivity: [
      {
        required: false,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    b64Picture: [
      {
        validator: this.validateImage,
        trigger: "blur",
      },
    ],
  };

  get fileList() {
    return this.card && this.card.b64Picture
      ? [
          {
            name: "Miniatura",
            url: this.card.b64Picture,
          },
        ]
      : [];
  }

  get showTimeActivityFields() {
    return this.disableTimeActivity;
  }

  @Watch("card.activeDays")
  changeActiveDays(value: number[]) {
    if (value.length === 0) {
      this.card.startTimeActivity = "";
      this.card.endTimeActivity = "";
      this.disableTimeActivity = true;
      this.rules.startTimeActivity[0].required = false;
      this.rules.endTimeActivity[0].required = false;
    } else if (this.disableTimeActivity) {
      this.rules.startTimeActivity[0].required = true;
      this.rules.endTimeActivity[0].required = true;
      this.disableTimeActivity = false;
    }
  }

  @Watch("card.url")
  changedUrl(url: string) {
    this.$emit("changedUrl", url);
  }

  @Watch("card", { immediate: true })
  onCardChange(newValue: CardItem) {
    if (newValue.system.showImprovement || newValue.showImprovement) {
      this.getOfferTypes(this.systemId);
    }
  }

  created() {
    if (typeof this.card.startTimeActivity !== "string") {
      this.card.startTimeActivity = "";
    }
    if (typeof this.card.endTimeActivity !== "string") {
      this.card.endTimeActivity = "";
    }
  }

  getOfferTypes(systemId: number) {
    this.loadingOfferTypes = true;
    SystemServices.offerType(systemId, {
      order: "name:asc",
    })
      .then(res => {
        this.offerTypes = res.data.data.rows;
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.loadingOfferTypes = false;
      });
  }

  showDrawer() {
    this.closeDrawer(true);
  }

  closeDrawer(value: boolean) {
    this.showConfigDrawer = value;
  }

  validateImage(rule: any, value: any, callback: any) {
    if (!this.formData.has("file") && this.card.b64Picture === "") {
      callback(new Error("Este campo es obligatorio."));
    } else {
      // (this.$refs.cardForm as ElForm).validateField("b64Picture");

      callback();
    }
  }

  checkFileType(fileType: string): boolean {
    return this.imgTypeRegex.test(fileType);
  }

  handleChange(file: AssetUpload, fileList: AssetUpload[]) {
    if (file.size > this.maxImageSize) {
      Notify.error("El tamaño de la imágen debe ser menor que 500kb");
      fileList.pop();
    } else if (!this.checkFileType(file.raw.type)) {
      Notify.error("El archivo debe de ser una imagen");
      fileList.pop();
    } else {
      this.buildFormData(file);
    }
  }

  buildFormData(file: AssetUpload) {
    this.formData.append("file", this.renameFile(file.raw, Date.now()));
  }

  renameFile(image: File, idImage: number): File {
    const newName = `${idImage}${this.getFileExtension(image.name)}`;
    return new File([image], newName, { type: image.type });
  }

  handleRemove(file: AssetUpload, fileList: AssetUpload[]) {
    this.formData.delete("file");
    this.card.b64Picture = "";
  }

  getFileExtension(filename: string): string {
    if (filename.includes(".")) {
      const lastIndex = filename.lastIndexOf(".");
      return filename.substring(lastIndex);
    }

    return "";
  }

  imageFile() {
    return this.formData;
  }

  clearFormData() {
    this.formData.forEach((value, key) => {
      this.formData.delete(key);
    });
  }

  validateForm(): boolean {
    let isValid = false;
    (this.$refs.cardForm as ElForm).validate(valid => {
      isValid = valid;
    });

    return isValid;
  }
}
