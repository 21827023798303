import Column from "@/models/Column";

export default class Rule {
  id?: number;
  operator!: string;
  value!: string | number;
  active = true;
  columnId!: number;
  column!: Column;
  systemId!: number;
  cardId!: number;
  createdAt?: Date;
  updatedAt?: Date;
}
