
import { Vue, Component, Prop } from "vue-property-decorator";
import ConfigForm from "./CardConfigForm.vue";
import { Setting } from "@/api/helpers/Api";
import { Count } from "@/api/Gondor";
import { KeySystem } from "@/api/helpers/KeySystem";

@Component({
  components: {
    ConfigForm,
  },
})
export default class ConfigDrawer extends Vue {
  @Prop({ required: true }) readonly model!: Setting[];
  @Prop({ required: true }) readonly cardId!: number;
  @Prop({ required: true }) readonly showDrawer!: boolean;
  @Prop({ required: true }) readonly loading!: boolean;
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly systemId!: number;
  @Prop({ required: true }) readonly keys!: Count<KeySystem>;

  closeDrawer() {
    this.$emit("closeDrawer", false);
  }

  updateModel(cardId: number) {
    this.$emit("updateModel", cardId);
  }
}
