import { Gondor } from "../Gondor";

const ACTIONS = {
  MONTHLY_PAYMENT: "/monthlyPayment/",
  MONTHLY_PAYMENT_ITEM: "/monthlyPayment/{id}/",
  ADD_SYSTEMS: "/monthlyPayment/add/system",
  REMOVE_SYSTEMS: "/monthlyPayment/remove/system",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.MONTHLY_PAYMENT, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.MONTHLY_PAYMENT_ITEM.replace("{id}", id));
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.MONTHLY_PAYMENT, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(
    ACTIONS.MONTHLY_PAYMENT_ITEM.replace("{id}", id),
    body
  );
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.MONTHLY_PAYMENT_ITEM.replace("{id}", id));
}

export function addSystems(body: object) {
  return Gondor.API.post(ACTIONS.ADD_SYSTEMS, body);
}

export function removeSystems(body: object) {
  return Gondor.API.post(ACTIONS.REMOVE_SYSTEMS, body);
}
