import { Gondor } from "../Gondor";

const ACTIONS = {
  COMPANY_ACTIVITY: "/companyActivity/",
  COMPANY_ACTIVITY_ITEM: "/companyActivity/{id}/",
  ADD_SYSTEMS: "/companyActivity/add/system",
  REMOVE_SYSTEMS: "/companyActivity/remove/system",
};

export function find(params?: object) {
  return Gondor.API.get(ACTIONS.COMPANY_ACTIVITY, { params });
}

export function findOne(id: number | string) {
  id = id.toString();
  return Gondor.API.get(ACTIONS.COMPANY_ACTIVITY_ITEM.replace("{id}", id));
}

export function create(body: object) {
  return Gondor.API.post(ACTIONS.COMPANY_ACTIVITY, body);
}

export function update(id: number | string, body: object) {
  id = id.toString();
  return Gondor.API.patch(
    ACTIONS.COMPANY_ACTIVITY_ITEM.replace("{id}", id),
    body
  );
}

export function destroy(id: number | string) {
  id = id.toString();
  return Gondor.API.delete(ACTIONS.COMPANY_ACTIVITY_ITEM.replace("{id}", id));
}

export function addSystems(body: object) {
  return Gondor.API.post(ACTIONS.ADD_SYSTEMS, body);
}

export function removeSystems(body: object) {
  return Gondor.API.post(ACTIONS.REMOVE_SYSTEMS, body);
}
