
import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";
import CardForm from "@/components/CardForm.vue";
import RuleForm from "@/components/RuleForm.vue";
import * as CardServices from "@/api/helpers/Card";
import * as RuleServices from "@/api/helpers/Rule";
import Card, { CardItem } from "@/models/Card";
import Rule from "@/models/Rule";
import Column from "@/models/Column";
import Notify from "@/utils/notifications";
import _ from "underscore";

@Component({ components: { CardForm, RuleForm } })
export default class CardDrawer extends Vue {
  @Prop() card!: Card;
  @Prop() show!: boolean;
  @Prop() updtType!: string;
  @Prop() systemId!: number;
  @Prop() columns!: Array<Column>;
  @Ref() readonly form!: CardForm;

  oldCard = {};
  oldRules: Array<Rule> = [];
  toAddRules: Array<Rule> = [];
  toUpdtRules: Array<Rule> = [];
  toDeleteRules: Array<Rule> = [];
  loading = false;
  fullscreenLoading = false;

  async updateCard() {
    this.fullscreenLoading = true;
    switch (this.updtType) {
      case "card":
        if (this.validateCardForm() && this.card && this.card.id) {
          const cardCopy = { ...this.card } as CardItem;
          this.loading = true;
          if (
            typeof this.card.activeDays === "undefined" ||
            (Array.isArray(this.card.activeDays) &&
              this.card.activeDays.length === 0)
          ) {
            cardCopy.startTimeActivity = null;
            cardCopy.endTimeActivity = null;
          }
          if (this.form.imageFile().has("file")) {
            const imageUrl = await CardServices.uploadImage(
              this.form.imageFile()
            );
            cardCopy.b64Picture = imageUrl.data.data.url;
            this.form.clearFormData();
          }

          CardServices.update(this.card.id, cardCopy)
            .then(() => {
              Notify.successful("Tarjeta actualizada exitosamente.");
              this.$emit("updateCard");
              this.$emit("closeDrawer", false);
            })
            .catch(error => {
              Notify.gebServerError(error);
            })
            .finally(() => {
              this.loading = false;
              this.fullscreenLoading = false;
            });
        }
        this.fullscreenLoading = false;
        break;
      case "rule":
        {
          this.clearEmptyRules();
          this.splitRules();

          /* Delete card rules */
          _.each(this.toDeleteRules, (r: Rule) => {
            if (r.id)
              RuleServices.destroy(r.id)
                .then(() => {
                  return true;
                })
                .catch(error => {
                  Notify.gebServerError(error);
                  return true;
                });
          });

          /* Create card rules */
          _.each(this.toAddRules, (r: Rule) => {
            r.systemId = this.systemId;
            if (this.card && this.card.id) r.cardId = this.card.id;
            RuleServices.create(r)
              .then(() => {
                return true;
              })
              .catch(error => {
                Notify.gebServerError(error);
                return true;
              });
          });

          /* Update card rules */
          _.each(this.toUpdtRules, (r: Rule) => {
            if (r.id)
              RuleServices.update(r.id, r)
                .then(() => {
                  return true;
                })
                .catch(error => {
                  Notify.gebServerError(error);
                  return true;
                });
          });
          Notify.successful("Reglas actualizadas exitosamente.");
          this.$emit("updateCard");
          this.$emit("closeDrawer", false);
        }
        this.fullscreenLoading = false;
        break;
    }
  }

  splitRules() {
    const currentRules = _.filter(
      this.oldRules,
      rule => rule.cardId === this.card.id
    );
    const deleted = _.difference(currentRules, this.card.rules);
    const added = _.difference(this.card.rules, currentRules);
    const updated = _.difference(currentRules, [...deleted, ...added]);

    this.toDeleteRules = deleted;
    this.toAddRules = added;
    this.toUpdtRules = updated;
  }

  closeDrawer() {
    this.loading = false;
    Object.assign(this.card, this.oldCard);
    this.$emit("closeDrawer", false);
  }

  changedUrl(url: string) {
    this.card.urls = [url];
  }

  validateCardForm() {
    const valid = this.form.validateForm();
    if (
      !valid ||
      (this.card.activeDays.length &&
        (!this.card.startTimeActivity || !this.card.endTimeActivity))
    ) {
      return false;
    }

    return true;
  }

  clearEmptyRules() {
    const ruleForm = this.$refs["rules"] as HTMLFormElement;
    ruleForm.clearRules();
  }

  get title() {
    return this.updtType == "card"
      ? "Actualizar tarjeta"
      : "Actualizar reglas para " + this.card.name;
  }

  get showDrawer() {
    return this.show;
  }

  @Watch("show")
  changedShow() {
    if (this.show) {
      Object.assign(this.oldCard, this.card);
      if (this.updtType == "rule" && this.card && this.card.id) {
        this.fullscreenLoading = true;
        CardServices.findOne(this.card.id)
          .then(res => {
            this.card.rules = res.rules;
            Object.assign(this.oldRules, this.card.rules);
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
      }
    }
  }
}
